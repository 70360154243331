<template>
    <div>
        <multiselect v-model="selected"
                     label="text"
                     track-by="value"
                     :placeholder="$t('search')"
                     :open-direction="openDirection"
                     :options="options"
                     :searchable="true"
                     :internal-search="false"
                     :close-on-select="true"
                     :show-no-results="true"
                     :select-label="''"
                     :selected-label="''"
                     :deselect-label="''"
                     :class="validateError != '' ? 'box-border-color' : ''"
                     @search-change="search"
                     @input="handleInput($event, multiple)"
        >
            <template slot="selection" slot-scope="{ values, search, isOpen }" v-if="multiple">
			    <span class="multiselect__single" v-if="values.length && !isOpen">
	                {{ translateNSelected(values) }}
                </span>
            </template>
            <span slot="noOptions">{{ $t('no_options') }}</span>
            <span slot="noResult">{{ $t('no_result') }}</span>
        </multiselect>
        <span class="invalid-feedback-custom"
              v-show="validateError"
              v-html="validateError">
        </span>
    </div>
</template>
<script>
// Helpers
import translateNSelected from '@/helpers/translateNSelected';
import handleInput from '@/helpers/handleInput';
import setSelected from '@/helpers/setSelected';

// Services
import StaffService from '@/services/StaffService';

export default {
    props: {
        value: {
            default: null
        },
        validateError: {
            type: String,
            default: ''
        },
        multiple: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: ''
        },
        getId: {
            type: Boolean,
            default: false
        },
        openDirection: {
            type:String,
            default:'bottom'
        }
    },
    watch: {
        value: function (newValue) {
            this.selected = this.setSelected(newValue, this.options, this.multiple)
        },
        text: {
            handler: function (val, oldVal) {
                if (val) {
                    this.options = [{text: val, value: this.value}];
                    this.selected = this.options.filter(c => c.value == this.value);
                }
            },
            immediate: true
        }
    },
    data() {
        return {
            options: [],
            selected: null
        }
    },
    methods: {
        translateNSelected: translateNSelected,
        handleInput: handleInput,
        setSelected: setSelected,
        search(key) {
            if (key.length > 2) {
                const config = {params: {key: key}}
                setTimeout(()=>{
                    StaffService.search(config)
                        .then((response) => {
                            let data = response.data.data;
                            this.options = []
                            data.forEach((item) => {
                                this.options.push({
                                    value: this.getId ? item.id : item.user_id,
                                    text: item.name + ' ' + item.surname
                                });
                            });
                        })
                        .then(() => {
                            this.selected = this.setSelected(this.value, this.options, this.multiple)
                        })
                        .catch(err => this.showErrors(err))
                },300)
            }
        }
    }
}
</script>
